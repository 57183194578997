import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { AlertError } from "../error-handler";
import { ProjectService } from "../project/project.service";

@Injectable({
	providedIn: "root",
})
export class PuestosService {
	constructor(
		private http: HttpClient,
		private authSvc: AuthService,
		private projectSvc: ProjectService,
	) {}

	getPuestoById(puestoId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/puestos/" + puestoId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPuestoByPersonAndDate(personId, periodId?): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
					},
				}),
			};
			if (periodId) {
				options.params = options.params.set("periodId", periodId);
			}

			const url = environment.urlDomain + "/api/v1/puestos/byPersonAndDate";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	deletePuesto(personId, puestoID): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/puestos/" + personId + "/" + puestoID;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	postPuesto(personId, puestoID): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const body = {
				id_persona: personId,
				id_puesto: puestoID,
			};

			const url = environment.urlDomain + "/api/v1/puestos/savePuesto";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}
}
